<template>
  <div class="row">
    <div class="col-12 col-md-4">
      <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
        $t('salon-bookings.input-label.filters')
      }}</label>
      <Multiselect
        v-model="selectedCategory"
        :options="items"
        label="label"
        :searchable="false"
        :select-label="''"
        :hide-selected="true"
        :placeholder="$t('products.table_header.select_category')"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ $t(props.option.title) }}</span>
          </span>
        </template>
        <template slot="caret" slot-scope="{ option, toggle }">
          <div
            class="multiselect__select d-flex align-items-center justify-content-center"
            @mousedown.prevent.stop="toggle()"
          >
            <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ $t(props.option.title) }}</span>
          </div>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductFilter',
  components: { Multiselect },
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedCategory: null,
      page: 1,
      filtersApplied: false
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    }),
    items() {
      let firstItem = [
        {
          id: null,
          title: this.$t('products.table_header.all_categories')
        }
      ]

      return firstItem.concat(this.categories)
    }
  },
  watch: {
    selectedCategory: {
      handler(newVal) {
        if (newVal) {
          this.filtersApplied = true

          this.applyFiltersEmit()
        }
      }
    },
    filtersApplied(newVal) {
      if (newVal) {
        this.applyFiltersEmit()
      }
    }
  },
  methods: {
    applyFiltersEmit() {
      let params = {
        category_id: this.selectedCategory.id
      }

      this.$emit('apply-filters', params)
    }
  }
}
</script>

<style lang="scss" scoped></style>
