<template>
  <main class="navbar-offset-tablet salon-flow">
    <PageNavigator :page-title="$t('products.breadcrumbs-list-label')" :has-back="false" :class="'py-0-8'">
      <template v-slot:action>
        <div class="nav-tabs border-0">
          <b-link :to="'/products'" exact-active-class="active" class="btn btn-checkbox px-1-5 mr-1-5">
            <span class="font-weight-medium poppins-font">
              {{ $t('common.forms.button.products') }}
            </span>
          </b-link>
          <b-link :to="'/categories'" exact-active-class="active" class="btn btn-checkbox px-1-5 mr-1-5 poppins-font">
            {{ $t('common.forms.button.categories') }}
          </b-link>

          <b-link :to="'/products/create'" exact-active-class="active" class="btn btn-primary px-4 py-1 poppins-font">
            {{ $t('products_create.breadcrumbs-list-label') }}
          </b-link>
        </div>
      </template>
    </PageNavigator>

    <div class="px-1-5 pt-7-5 full-page-height">
      <ProductFilter :categories="categories" :class="'mb-1-5'" @apply-filters="applyFilters"></ProductFilter>
      <div class="d-flex flex-wrap h-100 justify-content-center">
        <ProductsTable v-if="!isLoading" :items="products.data" @delete-item="deleteProduct"></ProductsTable>
        <CustomPagination
          :data="products"
          :show-disabled="true"
          @pagination-change-page="changePaginationPage"
        ></CustomPagination>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import ProductsTable from '@/components/tables/ProductsTable'
import CustomPagination from '@/components/CustomPagination'
import ProductFilter from '@/components/filters/ProductFilter'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'List',
  components: {
    PageNavigator,
    ProductsTable,
    CustomPagination,
    ProductFilter
  },
  data() {
    return {
      isLoading: false,
      filtersApplied: false,
      payloadData: {
        salon_id: null,
        page: 1,
        sort: '-category.title'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      products: 'products/getProducts',
      categories: 'categories/getCategoriesForSelect'
    })
  },
  mounted() {
    this.fetchData()
    if (this.accountInfo.salon_id) {
      this.fetchCategoriesForSelect({ salon_id: this.accountInfo.salon_id })
        .then(result => {})
        .catch(errors => {})
    }
  },
  methods: {
    ...mapActions({
      fetchProdcuts: 'products/getAll',
      deleteProductById: 'products/deleteProduct',
      fetchCategoriesForSelect: 'categories/getAllForSelect',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    setLoadingFlag(val) {
      this.isLoading = val
    },
    setPreviousPageForRequest() {
      if (
        this.products.data.length === 0 &&
        this.products.current_page === this.products.last_page &&
        this.products.current_page !== 1
      ) {
        this.payloadData.page = this.payloadData.page - 1
      }
    },
    changeSort(val) {
      this.payloadData.sort = val

      this.fetchProdcuts(this.payloadData)
    },
    changePaginationPage(page = 1) {
      this.payloadData.page = page

      this.fetchProdcuts(this.payloadData)
    },
    confirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.delete_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    deleteProcess(params) {
      this.setLoadingFlag(true)

      this.deleteProductById(params)
        .then(() => {
          if (this.products.data.length === 0 || this.products.total === 10) {
            this.setPreviousPageForRequest()
            this.fetchData()
          }

          this.setLoadingFlag(false)

          this.setSuccess({
            messageI18Key: `common.alerts.actions.deleted`,
            successCode: 200,
            successExists: true
          })
        })
        .catch(error => {
          this.setLoadingFlag(false)

          this.setError({
            type: 'delete_dayoff',
            statusCode: error.code,
            messageI18Key: 'common.alerts.actions.global_error'
          })
        })
    },
    deleteProduct(params) {
      this.confirmMessage()
        .then(value => {
          if (value) {
            this.deleteProcess(params)
          }
        })
        .catch(error => {})
    },
    fetchData() {
      if (this.accountInfo.salon_id) {
        this.payloadData.salon_id = this.accountInfo.salon_id

        this.setLoadingFlag(true)

        this.fetchProdcuts(this.payloadData)
          .then(() => {
            this.setLoadingFlag(false)
          })
          .catch(() => {
            this.setLoadingFlag(false)
            this.setError({
              errorType: 'dayoff_loading',
              messageI18Key: 'common.alerts.actions.global_error'
            })
          })
      }
    },
    activatedFilter() {
      this.filtersApplied = true
    },
    applyFilters(params) {
      if (params.category_id) {
        let payloadData = {
          ...this.payloadData,
          ...params
        }

        this.fetchProdcuts(payloadData)
          .then(() => {
            this.setLoadingFlag(false)
          })
          .catch(() => {
            this.setLoadingFlag(false)
            this.setError({
              errorType: 'dayoff_loading',
              messageI18Key: 'common.alerts.actions.global_error'
            })
          })
      } else {
        this.fetchData()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
